import React, {useEffect, useState} from 'react';
import styles from './StationCard.module.css';
import station from "../../../img/station.svg"
import {Badge, Button, Modal} from 'react-bootstrap';
import {fetchStatistic} from "../FetchServises/StatisticService";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {fetchConnectors} from "../FetchServises/ConnectorService";

const StationCard = (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('s_token');
    const [statResult, setStatResult] = useState([]);
    const [show, setShow] = useState(false);
    const [connectors, setConnectors] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleHistoryClick = async (sn) => {
        const result = await fetchStatistic(sn, token); // Используем импортированную функцию
        setStatResult(result);
        handleShow();
    }
    const handleDetail = (chargeStation) => {
        if (chargeStation.heartbeat === null) {
            toast.error('Станция не в сети! ', {
                position: "top-center",
                autoClose: 3000
            });
            return;
        }
        navigate('/step55', {state: {chargeStation}});
    };

    // Получим коннекторы станции
    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchConnectors(props.station.sn, token); // Используем импортированную функцию
            if (result) {
                setConnectors(result);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 15000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {/* Модальное окно статистики зарядок*/}
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Статистика транзакций</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {statResult.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>Энергия (кВтч)</th>
                                <th>Начало</th>
                                <th>Конец</th>
                                <th>Ошибка</th>
                            </tr>
                            </thead>
                            <tbody>
                            {statResult.map((stat) => (
                                <tr key={stat.id}>
                                    <td>{stat.consumeEnergy}</td>
                                    <td>{new Date(stat.dataStartTransaction).toLocaleString()}</td>
                                    <td>{new Date(stat.dataEndTransaction).toLocaleString()}</td>
                                    <td>{stat.stopError}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Нет данных для отображения</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Модальное окно статистики зарядок*/}
            <div className={`${styles.card} ${props.station.heartbeat === null ? styles.inactiveCard : ''}`}
                 onClick={() => {
                     handleDetail(props.station)
                 }}>
                <div className={styles.infoSection}>
                    <h3>{props.station.nickName}</h3>
                    <p>{props.station.heartbeat === null ? 'Не в сети' : 'В сети'}</p>
                    <ul className={styles.customList}>
                        {connectors?.map((con, index) => {
                            if (con.type === "station") {
                                return null; // Пропускаем элемент, если type равен "station"
                            }
                            // Устанавливаем цвет бейджа в зависимости от статуса
                            let badgeStatus;
                            let badgeLabel = con.status;
                            if (con.status === 'Available') {
                                badgeStatus = 'success';
                            } else if (con.status === 'Preparing') {
                                badgeStatus = 'warning';
                            } else if (con.status === 'Charging') {
                                badgeStatus = 'danger';
                            } else if (con.status === 'Finishing') {
                                badgeStatus = 'primary';
                            } else {
                                badgeStatus = 'secondary';
                            }
                            return (
                                <li key={index} className={styles.customBadge}>
                                    <Badge
                                        bg={badgeStatus}
                                        data-label={badgeLabel}
                                        className={`${styles.badgeWithLabel} me-2`}
                                    >
                                        {con.type}
                                    </Badge>
                                </li>
                            );
                        })}
                    </ul>
                    <small className={styles.infoText}>Нажмите на станцию для начала зарядки</small>
                </div>
                <div className={styles.imageSection}>
                    <img src={station} className={styles.stationImage} alt="q"/>
                    <button type="button" className="btn btn-outline-secondary btn-sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleHistoryClick(props.station.sn);
                            }}>История
                    </button>
                </div>
            </div>
        </>
    );
};

export default StationCard;

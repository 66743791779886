import config from "../../../settings/config";

// Функция для получения списка коннекторов
export const fetchStatistic = async (sn, token) => {
    try {
        const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/local-stat-tab/all-transactions?sn=${sn}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './StartScreen.module.css'
import {useNavigate} from "react-router-dom";

const StartScreen = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('m_token') && !localStorage.getItem("pin")) {
            /*toast.error('1-Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });*/
            navigate('/dash');
            return;
        }
        if (localStorage.getItem("pin")) {
            navigate('/pin');
        } else {
            // clear local storage (удаляем из localStorage всю информацию за исключением телефона)
            for (let i = 0, len = localStorage.length; i < len; ++i) {
                if (localStorage.key(i) === 'phone') {
                    continue;
                }
                if (localStorage.key(i) === 'vkloginprocess') {
                    continue;
                }
                localStorage.removeItem(localStorage.key(i));
            }
            navigate('/start');
        }
    }, []);

    return (
        <div className={styles.container}>

        </div>
    );
}

export default StartScreen;
import React, {useEffect} from 'react';
import styles from './PostRegScreen.module.css';
import {checkToken} from "../../Utils/TokenService";
import {useLocation, useNavigate} from "react-router-dom";

const PostRegScreen = () => {
    const navigate = useNavigate();
    // Проверим токен для мобильного бэка
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    // Получим state который пришёл из RegistrationForm, он нам нужен для получения имени юзера
    const {state} = useLocation();

    const handleClick = () => {
        navigate('/postreg2')
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Добро пожаловать<br/> {state?.name}</h1><br/>
                <p>Легко находите
                    ближайшие зарядные станции</p>
                <p>и подключайте
                    персональные зарядные устройства </p>
            </div>
            <div className={styles.submitWrapper}>
                {/*<button
                    type="button"
                    onClick={() => {
                        navigate('/profile')
                    }}
                    className={styles.submitButtonEnabled}

                >
                    Мой профиль
                </button>*/}
                <button
                    type="button"
                    onClick={handleClick}
                    className={styles.submitButtonEnabled}

                >
                    Далее
                    <span className={styles.arrow}>→</span>
                </button>
            </div>
        </div>
    )
        ;
};

export default PostRegScreen;

import React, {useEffect, useState} from 'react';
import styles from './ConnectorCard.module.css';
import {checkToken} from "../../../Utils/TokenService";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import BatteryGauge from 'react-battery-gauge'
/*import mode3Type2Image from "../../../../img/type2.webp" // Путь к изображению Mode3 Type2
import CHAdeMoImage from '../../../../img/chademo.webp'; // Путь к изображению CHAdeMo
import GBT2Image from '../../../../img/chademo.webp'; // Путь к изображению GBT2
import CCSImage from '../../../../img/ccs.webp'; // Путь к изображению CCS
import GBTImage from '../../../../img/gbt.webp'; // Путь к изображению GB/T*/
import mode3Type2Image from "../../../../img/connectors/type2.png" // Путь к изображению Mode3 Type2
import CHAdeMoImage from '../../../../img/connectors/chademo.png'; // Путь к изображению CHAdeMo
import GBTImage from '../../../../img/connectors/chademo.png'; // Путь к изображению GB/T
import GBT2Image from '../../../../img/connectors/gbt.png'; // Путь к изображению GBT2
import CCSImage from '../../../../img/connectors/ccs.png'; // Путь к изображению CCS


const ConnectorCard = (props) => {
    const {
        connectorId,
        sn,
        status,
        type
    } = props;

    // Определяем картинку на основе типа разъема
    let connectorImage;
    switch (type) {
        case 'CCS':
            connectorImage = CCSImage;
            break;
        case 'GB/T':
            connectorImage = GBTImage;
            break;
        case 'GBT2':
            connectorImage = GBT2Image;
            break;
        case 'Mode3_type2':
            connectorImage = mode3Type2Image;
            break;
        case 'CHAdeMo':
            connectorImage = CHAdeMoImage;
            break;
        default:
            connectorImage = mode3Type2Image; // Значение по умолчанию, если тип не найден
    }

    const config = require('../../../../settings/config.js');
    const token = localStorage.getItem('s_token');
    const [connectorStatus, setConnectorStatus] = useState(null);
    // Чекаем используемые токены
    const navigate = useNavigate();
    useEffect(() => {
        const m_token = localStorage.getItem('s_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    // Функция для старта зарядки
    const handleStart = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/start?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging started', {autoClose: 3000});
            } else {
                toast.error('Failed to start charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        }
    };

    // Функция для остановки зарядки
    const handleStop = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/stop?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging stopped', {autoClose: 3000});
            } else {
                toast.error('Failed to stop charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        }
    };

    // Функция для опроса состояния коннектора каждые 15 секунд
    useEffect(() => {
        const fetchConnectorStatus = async () => {
            // Проверяем токенг перед каждым запросом
            if (!checkToken(token)) {
                navigate('/step2');
            }
            try {
                const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/last-transaction?sn=${sn}&type=${type}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setConnectorStatus(data);
            } catch (error) {

            }
        };
        // Первоначальный запрос
        fetchConnectorStatus();
        // Запуск интервала для опроса каждые 15 секунд
        const intervalId = setInterval(fetchConnectorStatus, 15000);
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [sn]);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        if (hrs > 0) {
            return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        } else {
            return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        }
    };

    const typeTransform = (type) => {
        switch (type) {
            case 'CCS':
                return 'CCS';
            case 'GB/T':
                return 'GB/T';
            case 'GBT2':
                return 'GB/T2';
            case 'Mode3_type2':
                return 'Type2';
            case 'Mode3_type1':
                return 'Type1';
            case 'CHAdeMo':
                return 'CHAdeMo';
            default:
                return type;
        }
    };

    return (
        <div className={`${styles.card}`}
             onClick={() => {
             }}>
            <div className={styles.infoSection}>

                {/* <h3>{type === "Mode3_type2" ? "Type2" : type}</h3>*/}
                <h3>{typeTransform(type)}</h3>
                <p>{status}</p>
                <small className={styles.infoText}>
                    <button type="button" className="btn btn-outline-secondary btn-sm"
                            onClick={status === "Charging" ? handleStop : handleStart}> {status === "Charging" ? "Stop" : "Start"}
                    </button>
                </small>
            </div>
            {status === "Charging" ?
                <div className={styles.infoSection}>
                    {/* <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>*/}
                    <BatteryGauge value={40} size={90} animated={true} charging={true} aspectRatio={0.3}/>
                    {/*  <progress value={null}/>*/}

                    <p>Ток: {connectorStatus?.amperage} A<br/>
                        Мощность: {connectorStatus?.power} Вт<br/>
                        Заряжено: {connectorStatus?.consumeEnergy / 1000} кВт.ч<br/>
                        Время: {formatTime(connectorStatus?.chargeTime || 0)}</p>
                </div> : <div className={styles.infoSection}>Подключите<br/> коннектор<br/> к автомобилю</div>
            }
            <div className={styles.imageSection}>
                <img src={connectorImage} className={styles.stationImage} alt="q"/>
            </div>
        </div>
    );
};

export default ConnectorCard;

import React, {useEffect} from 'react';
import styles from './NStartScreen.module.css';
import {useNavigate} from "react-router-dom";

const NStartScreen = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('vkloginprocess')) {
            navigate('/step2');
        }
    }, []);

    const handleBegin = () => {
        navigate('/step2');
    }

    return (
        <div className={styles.container}>
            <div className={styles.imageWrapper}>
                {/* Добавьте изображение через CSS background */}
            </div>
            <div className={styles.content}>
                <h1 className={styles.title}>[PSS]</h1>
                <p className={styles.description}>
                    Откройте для себя простой и быстрый доступ к зарядным станциям по всему миру
                </p>
                <button className={styles.startButton} onClick={handleBegin}>
                    Начать
                    <span className={styles.arrow}>→</span>
                </button>
            </div>
        </div>
    );
};

export default NStartScreen;

import React, {useEffect, useRef, useState} from 'react';
import styles from './RegisterNewUserForm.module.css';
import {InputText} from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import {Toast} from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown';

const groupedAutos = [
    {
        label: 'Leapmotor',
        code: 'Leapmotor',
        items: [
            {label: 'Leapmotor C01', value: 'С01'},
            {label: 'Leapmotor C11', value: 'С11'},
            {label: 'Leapmotor C16', value: 'С16'},
            {label: 'Leapmotor S01', value: 'S01'}
        ]
    },
    {
        label: 'Nissan',
        code: 'Nissan',
        items: [
            {label: 'Ariya', value: 'Ariya'},
            {label: 'LEAF', value: 'LEAF'},
            {label: 'NV200', value: 'NV200'}
        ]
    },
    {
        label: 'Tesla',
        code: 'Tesla',
        items: [
            {label: 'Cybertruck', value: 'Cybertruck'},
            {label: 'Model 3', value: 'Model 3'},
            {label: 'Model S', value: 'Model S'},
            {label: 'Model X', value: 'Model X'},
            {label: 'Model Y', value: 'Model Y'}
        ]
    }
];

const RegisterForm = () => {

    const formData = {
        name: '',
        surname: '',
        password: '',
        email: '',
        mmavto: '', // Марка и модель автомобиля
        phone: '',
        birthDate: ''
    }

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [mmavto, setMmavto] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const toast = useRef(null);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const groupedItemTemplate = (option) => {
        return (
            <div className={styles.dropdown}>
                <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                     className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{width: '18px'}}/>
                <div>{option.label}</div>
            </div>
        );
    };

    const handleBlur = (e) => {
        let email = e.target.value; // Получаем значение поля email
        if (!validateEmail(email)) {
            e.target.style.borderColor = 'red';
            setIsDisabled(true);
        } else {
            e.target.style.borderColor = 'green';
            setIsDisabled(false);
        }
    };

    useEffect(() => { // Проверяем поля email и password на пустоту при каждом рендере формы
        (emailInput.current.value === "") ? emailInput.current.style.borderColor = 'red' : emailInput.current.style.borderColor = 'green';
        (passwordInput.current.value === "") ? passwordInput.current.style.borderColor = 'red' : passwordInput.current.style.borderColor = 'green';
    });

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSubmit = (e) => {

        formData.name = name;
        formData.surname = surname;
        formData.email = email;
        formData.mmavto = mmavto;
        formData.birthDate = birthDate;
        formData.password = password;
        formData.phone = phone;

        // alert(JSON.stringify(formData));
        toast.current.show({severity: 'Success', summary: 'Info', detail: JSON.stringify(formData)});

        e.preventDefault();
        const queryParams = new URLSearchParams(formData).toString();
        fetch(`https://yourserver.com/register?${queryParams}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    };

    return (
        <div className={styles.container}>
            <Toast ref={toast}/>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.title}>Регистрация</div>
                <label htmlFor="name">ИМЯ</label>
                <InputText
                    id="name"
                    className={styles.input}
                    value={name}
                    onChange={(e) => setName(e.target.value)}/>

                <label htmlFor="surname">ФАМИЛИЯ</label>
                <InputText
                    id="surname"
                    className={styles.input}
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}/>

                <label htmlFor="email">EMAIL*</label>
                <InputText
                    id="email"
                    ref={emailInput}
                    className={styles.input}
                    value={email}
                    onBlur={handleBlur}
                    onChange={(e) => setEmail(e.target.value)}/>

                <label htmlFor="p">ТЕЛЕФОН</label>
                <InputMask
                    id="p"
                    className={styles.input}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    mask="(+7)999-999-99-99"
                    placeholder="(+7)999-999-99-99"/>

                <label htmlFor="password">ПАРОЛЬ*</label>
                <InputText
                    id="password"
                    ref={passwordInput}
                    className={styles.input}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}/>

                {/* <label htmlFor="mmavto">МОДЕЛЬ АВТОМОБИЛЯ</label>
                <InputText
                    id="mmavto"
                    className={styles.input}
                    value={mmavto}
                    onChange={(e) => setMmavto(e.target.value)}/>*/}

                <div className={styles.dropdown}>
                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={groupedAutos}
                              optionLabel="label"
                              optionGroupLabel="label" optionGroupChildren="items"
                              optionGroupTemplate={groupedItemTemplate}
                              className="w-full md:w-14rem" placeholder="Модель автомобиля"/>
                </div>

                <label htmlFor="dob">ДАТА РОЖДЕННИЯ</label>
                <InputMask
                    id="dob"
                    className={styles.input}
                    value={birthDate} onChange={(e) => setBirthDate(e.target.value)}
                    mask="99.99.9999"
                    placeholder="__.__.____"/>

                <button
                    disabled={isDisabled}
                    className={styles.button}
                >
                    Зарегистрироваться
                </button>
            </form>


        </div>
    );
};

export default RegisterForm;

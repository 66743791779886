import React, {useEffect} from 'react';
import styles from './RegistrationStep4.module.css'
import {DeviceUUID} from "device-uuid";
import {toast} from "react-toastify";
import {checkToken} from "../../Utils/TokenService";
import {useNavigate} from "react-router-dom";


const RegistrationStep4 = (props) => {

    const config = require('../../../settings/config.js');

    const navigate = useNavigate();

    // Чекаем используемые токены
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    async function tokenUpdateHandle() {
        let uuid = new DeviceUUID().get();
        const authRequest = {
            username: localStorage.getItem('phone').replace(/\D/g, ''),
            password: null,
            deviceId: uuid
        }
        try {
            // я переделал бэк и возможно тут сломалось
            // я не переживаю по этому поводу. потому-что это место использоваться вроде-как не должно
            // но если что на бэке теперь я не использую body: JSON.stringify(authRequest), а username
            // беру из принципала, так, что отсюда достаточно передать m_token
            const response = await fetch(`${config.app.host}/api/v1/protected/updtoken`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(authRequest),
            });

            if (!response.ok) {
                toast.error('Error ', {
                    position: "top-center",
                    autoClose: 3000
                });
            }

            const token = await response.json();
            localStorage.setItem('s_token', token.skunsToken);
            toast.success('Токен успешно обновлён', {
                position: "bottom-center",
                autoClose: 3000
            });

        } catch (error) {
            toast.error('Ошибка:' + error.message, {
                position: "top-center-center",
                autoClose: 3000
            });
        }

    }

        return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.titleText}>Даёш HTTPS!!!!</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "Page title"*/}

                {/*start row block -- "An SMS will be sent to your number" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>А теперь нужно чтобы<br/>
                        S.K.U.N.S работал по HTTPS!!!
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "An SMS will be sent to your number"*/}

                {/*start row block - "Phone number and change it"*/}
                <div className={styles.row2}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    Иначе ничего

                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row4}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <span>не работает (</span>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row5}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                    <button
                        className={styles.button}
                        onClick={tokenUpdateHandle}
                    > Обновить токен
                    </button>
                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row6}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        {/*new line*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
            </div>
        );
    }
;

export default RegistrationStep4;

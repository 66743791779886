import {isJwtExpired} from "jwt-check-expiration";
import {updateSkunsToken} from "../MainRoad/FetchServises/UpdSkunsTokenService";

export const checkToken = (token) => {
    try {
        console.log(!isJwtExpired(token))
        if (!isJwtExpired(token))
            return true
        else return false;

        if (token) return true
        else return false

    } catch (error) {
        return true;
    }
}

export const checkAndRenewToken = (token) => {
    if (!checkToken(token)) {
        try {
            const renewToken = async () => {
                const newToken = await updateSkunsToken(localStorage.getItem('m_token'));
                localStorage.setItem('s_token', newToken.skunsToken);
                return newToken.skunsToken;
            }
            renewToken();
        } catch (error) {
            console.log(error.message);
        }
    } else {
        return token;
    }
}
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {checkToken} from "../../Utils/TokenService";
import StationCard from "../StationCard/StationCard";
import styles from './RegistrationStep5.module.css';
import mobileSignal from "../../../img/signal.svg";
import {Button, Modal} from "react-bootstrap";
import HelpIcon from "../HelpIcon/HelpIcon";
import {updateSkunsToken} from "../FetchServises/UpdSkunsTokenService";

const RegistrationStep5 = (props) => {
    const config = require('../../../settings/config.js');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    // const token = localStorage.getItem('s_token');
    const [token, setToken] = useState(localStorage.getItem('s_token'));
    const groupId = localStorage.getItem('groupId');

    // Чекаем используемые токены в первую очередь
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            toast.error('Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });
            navigate('/step2');
        }
    }, []);

    // HEADER-а - устанавливает ему заголовок
    useEffect(() => {
        props.setTitle('Мои зарядные станции');
    }, [props.setTitle]);

    // Состояние для управления модальным окном1
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            if (!checkToken(token)) {
                try {
                    const renewToken = async () => {
                        const newToken = await updateSkunsToken(localStorage.getItem('m_token'));
                        localStorage.setItem('s_token', newToken.skunsToken);
                        setToken(newToken.skunsToken);
                        console.log("renewToken");
                    }
                    renewToken();
                } catch (error) {
                    console.log(error.message);
                }
            }

            try {
                const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/table/get?groupId=${groupId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                setData(result.content);
            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <>
            {/* Модальное окно подключения станции*/}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Подключаем станцию</Modal.Title>
                </Modal.Header>
                <Modal.Body>Станция успешно подключена <br/> <b>Продолжаем?</b> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Нет
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setShowModal(false)
                    }}>
                        Да
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className={`container ${styles.containerWithBackground}`}>
                {data.map((item) => (
                    <StationCard station={item} key={item.id}/>
                ))}
                <div>
                    <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className={styles.submitButtonConnect}

                    >
                        Подключить новую станцию
                        <img src={mobileSignal} className={styles.mobileSignal} alt='signal'/>
                    </button>
                </div>
            </div>
            <HelpIcon/>
        </>
    );
};

export default RegistrationStep5;

import React, {createContext, useState} from 'react';

const AuthContext = createContext(null);

const AuthProvider = ({children}) => {
    const [authData, setAuthData] = useState(null);

    return (
        <AuthContext.Provider value={{authData, setAuthData}}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};

import React, {useState} from 'react';
import styles from "./HelpPage.module.css";
import {Accordion, Button, Form} from "react-bootstrap";

const HelpStep2 = () => {
    const [formData, setFormData] = useState({
        question: '',
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Отправка вопроса
        console.log('Вопрос отправлен:', formData.question);
        setFormData({question: ''});
    };
    return (
        <div className={styles.container}>
            <h2>Помощь</h2>
            <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Как зарегистрироваться?</Accordion.Header>
                    <Accordion.Body>
                        Для регистрации необходимо нажать кнопку "Зарегистрироваться" на главной странице и следовать
                        инструкциям.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Как использовать приложение?</Accordion.Header>
                    <Accordion.Body>
                        После входа в приложение, вы сможете находить зарядные станции и управлять своими сессиями
                        зарядки.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Как связаться с поддержкой?</Accordion.Header>
                    <Accordion.Body>
                        Вы можете связаться с нами через Telegram, по email или телефону, указанные ниже.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Form className={`mt-4 ${styles.form}`}>
                <Form.Group controlId="question">
                    <Form.Label>Ваш вопрос</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Введите ваш вопрос здесь..."/>
                </Form.Group>
                <Button variant="primary" type="submit" className={`"mt-3" ${styles.submitButton}`}>
                    Отправить
                </Button>
            </Form>

            <div className={`"mt-4" ${styles.contactLinks}`}>
                <p>Свяжитесь с нами:</p>
                <ul>
                    <li>Telegram: <a href="https://t.me/pss_support_bot" target="_blank" rel="noopener noreferrer">Наш
                        бот</a></li>
                    <li>Email: <a href="mailto:help@zs-pss.ru">help@zs-pss.ru</a></li>
                    <li>Телефон: <a href="tel:88005006290#1">8-800-500-62-90 (доб. 1)</a></li>
                </ul>
            </div>
        </div>
    );
};

export default HelpStep2;

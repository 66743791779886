import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../../context/AuthContext";
import Header from "../Header/Header";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styles from './HomeScreen.module.css'

const showToast = () => {
    toast.success('Login Successful');
}

const HomeScreen = () => {

    const {authData} = useContext(AuthContext);

    useEffect(() => {
        showToast();
    }, []);

    return (
        <div className={styles.block}>
            <Header title={"Home"}/>
            <div className={styles.container}>
                <pre className={styles.pre}>Token: {authData?.token}</pre>
                <pre className={styles.pre}>Group Id: {authData?.groupId}</pre>
            </div>
            <ToastContainer stacked/>
        </div>
    );
}

export default HomeScreen;
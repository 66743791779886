import React, {useEffect, useState} from 'react';
import stationImage from '../../../img/cs.webp'; // путь к изображению зарядной станции
import styles from './ChargingStationList.module.css'; // добавьте стили для нового дизайна

const ChargingStationList = ({stations}) => {
    const [chargingStations, setChargingStations] = useState([]);

    useEffect(() => {
        // Загружаем данные о зарядных станциях
        setChargingStations(stations);
    }, [stations]);

    return (
        <div className={styles.container}>
            {chargingStations.length === 0 ? (
                <p className={styles.noStations}>Зарядные станции не найдены</p>
            ) : (
                chargingStations.map((station, index) => (
                    <div key={index} className={styles.stationCard}>
                        {/* Фон с изображением станции */}
                        <div className={styles.stationImage}>
                            <img src={stationImage} alt="Зарядная станция" className={styles.stationIcon}/>
                        </div>
                        <div className={styles.stationInfo}>
                            <h3 className={styles.stationName}>{station.address}</h3>
                            <p className={styles.stationLocation}>{station.nickname}</p>
                            <p className={styles.stationStatus}>
                                Статус: {station.status === 'active' ? 'Активна' : 'Не активна'}
                            </p>
                        </div>
                        <div className={styles.stationActions}>
                            <button className={styles.startButton}>Начать зарядку</button>
                            {/*<button className={styles.stopButton}>Остановить</button>*/}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default ChargingStationList;

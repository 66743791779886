import React from 'react';
import styles from './Generic.module.css'


const Generic = (props) => {

        return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.roundButtons}>
                        <div className={styles.buttonsContainer}>
                            <div className={styles.buttonsContainerLeft}>
                                <div>
                                    <button className={styles.btRect}></button>
                                </div>
                                <div>
                                    <button className={styles.btMic}></button>
                                </div>
                            </div>
                            <div className={styles.buttonsContainerRight}>
                                <div>
                                    <button className={styles.btCoin}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "Page title"*/}

                {/*start row block -- "An SMS will be sent to your number" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>
                        <div className={styles.buttonsContainerBonus}>
                            <div>
                                <button className={styles.btBonus}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "An SMS will be sent to your number"*/}

                {/*start row block - "Phone number and change it"*/}
                <div className={styles.row2}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.buttonsContainerBonus}>
                        <div>
                            <button className={styles.myzs}></button>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row4}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.buttonsContainerCenter}>
                        <div>
                            <button className={styles.zSession}></button>
                        </div>
                        <div>
                            <button className={styles.shop}></button>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row5}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                    <div>
                        <button className={styles.find}></button>
                    </div>

                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row6}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        {/*new line*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
            </div>
        );
    }
;

export default Generic;

import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import axios from 'axios';
import styles from './UserProfile.module.css';
import {toast} from "react-toastify";
import {checkToken} from "../Utils/TokenService";
import {useNavigate} from "react-router-dom";

const UserProfile = () => {
    const config = require('../../settings/config.js');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        birthday: '',
        carBrand: '',
        carModel: ''
    });

    const navigate = useNavigate();
    // Чекаем используемые токены
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const [isLoading, setIsLoading] = useState(true); // Для отображения состояния загрузки

    // Состояние для управления модальным окном
    const [showModal, setShowModal] = useState(false);

    // Получаем токен из localStorage
    const token = localStorage.getItem('m_token');

    // Конфигурация заголовков с Bearer токеном
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`;
    }; // Функция форматирования даты

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${config.app.host}/api/v1/user/profile`, formData, axiosConfig)
            .then(response => {
                console.log('Profile updated:', response.data);
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    // Запрос данных профиля при монтировании компонента
    useEffect(() => {
        axios.get(`${config.app.host}/api/v1/user/getprofile`, axiosConfig)
            .then(response => {
                const profileData = response.data;
                // Заполняем форму полученными данными
                setFormData({
                    name: profileData.name || '',
                    email: profileData.email || '',
                    birthday: profileData.birthday ? formatDate(profileData.birthday) : '', // Преобразование даты
                    carBrand: profileData.carBrand || '',
                    carModel: profileData.carModel || ''
                });
                setIsLoading(false); // Убираем состояние загрузки
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                setIsLoading(false);
            });
    }, []); // Пустой массив указывает на вызов useEffect только при монтировании

    // Открытие модального окна
    const handleLogOut = () => {
        setShowModal(true);
    };

    // Обработка выхода из профиля
    const confirmLogOut = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/vk/logout`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                    /*body: JSON.stringify(
                        {
                            access_token: accessToken,
                        }),*/
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    localStorage.clear();
                    window.location.href = '/'; // Перенаправляем на страницу входа
                }

            } catch (error) {
                localStorage.clear();
                window.location.href = '/'; // Перенаправляем на страницу входа
            }
            // End request SMS code
            toast.success(`Logout successfuly!`, {
                position: "top-center",
                autoClose: 3000
            });
            localStorage.clear();
            window.location.href = '/'; // Перенаправляем на страницу входа
        }
    };

    return (
        <Container className={styles.profileContainer}>
            <Row className="justify-content-center">
                <Col xs={12} md={6}>
                    <h2 className="text-center mb-4">Профиль</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName" className="mb-3">
                            <Form.Label>Ваше имя:</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Введите ваше имя"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Введите ваш email"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formBirthday" className="mb-3">
                            <Form.Label>День рождения:</Form.Label>
                            <Form.Control
                                type="date"
                                name="birthday"
                                value={formData.birthday}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formCarBrand" className="mb-3">
                            <Form.Label>Марка автомобиля:</Form.Label>
                            <Form.Control
                                type="text"
                                name="carBrand"
                                value={formData.carBrand}
                                onChange={handleChange}
                                placeholder="Введите марку вашего автомобиля"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formCarModel" className="mb-3">
                            <Form.Label>Модель автомобиля:</Form.Label>
                            <Form.Control
                                type="text"
                                name="carModel"
                                value={formData.carModel}
                                onChange={handleChange}
                                placeholder="Введите модель вашего автомобиля"
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Отправить
                        </Button>

                        <Button variant="danger" type="button" className="w-100 mt-3" onClick={handleLogOut}>
                            Выйти из профиля
                        </Button>
                    </Form>
                </Col>
            </Row>

            {/* Модальное окно подтверждения выхода */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Подтверждение выхода</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы действительно хотите выйти из аккаунта?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Нет
                    </Button>
                    <Button variant="danger" onClick={confirmLogOut}>
                        Да
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default UserProfile;

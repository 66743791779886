import config from "../../../settings/config";
import {toast} from "react-toastify";

// Функция для получения списка коннекторов
export const fetchConnectors = async (sn, token) => {
    try {
        const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/connectors?sn=${sn}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new Error('Unauthorized');
            }
        }
        return await response.json();
    } catch (error) {
        toast.error('Response error: ' + error, {
            position: 'top-center',
            autoClose: 3000,
        });
        return null;
    }
};
// Функция для получения списка коннекторов

import React, {useEffect} from 'react';
import styles from './Dashboard.module.css';
import grid from "../../../img/btRect.svg"
import micIcon from "../../../img/btMic.svg"
import plusIcon from "../../../img/plus.svg"
import hdmi from "../../../img/hdmi.svg"
import chart from "../../../img/chart.svg"
import blockchain from "../../../img/blockchain.svg"
import scribble from "../../../img/scribble.svg"
import {useNavigate} from "react-router-dom";
import {checkToken} from "../../Utils/TokenService";
import {toast} from "react-toastify";

const Dashboard = () => {
    const navigate = useNavigate();

    // Чекаем используемые токены в первую очередь
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            toast.error('Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });
            navigate('/step2');
        }
    }, []);

    ////////////////////////////////////////////
    useEffect(() => {
        const handleBackButton = () => {
                if (window.confirm('Вы действительно хотите выйти из приложения?')) {
                    window.history.pushState(null, null, window.location.pathname);
                    window.close(); // Не всегда сработает в браузере, но сработает в standalone PWA
                } else {
                    // Do nothing
                }
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);
    ////////////////////////////////////////////


    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.iconGroup}>
                    <img src={grid} alt="grid" className={styles.iconInactive}/>
                    <img src={micIcon} alt="mic" className={styles.iconInactive}/>
                </div>
                <div className={styles.coinsInactive}>
                    36 монет
                    <img src={plusIcon} alt="plus" className={styles.plusIcon}/>
                </div>
            </div>

            <div className={styles.bonusSystemInactive}>
                Бонусная система
                <span className={styles.arrow}>→</span>
            </div>

            <div className={styles.cards}>
                <div className={styles.cardWide} onClick={() => {
                    navigate('/step5')
                }}>
                    <h3>Мои зарядные <br/> станции</h3>
                    <div className={styles.cardImage}>
                        <div className={styles.status}>зарядка ⚡</div>
                        <img src={hdmi} alt="charging"/>
                    </div>
                </div>

                <div className={styles.cardInactive}>
                    <h3>Зарядные сессии</h3>
                    <div className={styles.cardImageChart}>
                        <img src={chart} alt="sessions"/>
                    </div>
                </div>

                <div className={styles.cardInactive}>
                    <h3>Магазин</h3>
                    <div className={styles.cardImageBlockchain}>
                        <img src={blockchain} alt="store"/>
                    </div>
                </div>
            </div>

            <button className={styles.findStations}>
                Найти станции рядом <img src={scribble} alt="signal"/>
            </button>
        </div>
    );
};

export default Dashboard;

import React from 'react';
import styles from './VkExchangeToken.module.css'
import {useParams, useSearchParams} from "react-router-dom";


function VkExchangeToken(props) {

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className={styles.container}>
            <p>code: {searchParams.get("code")}</p><br/>
            <p>expires_in: {searchParams.get("expires_in")}</p><br/>
            <p>device_id: {searchParams.get("device_id")}</p><br/>
            <p>state: {searchParams.get("state")}</p><br/>
            <p>type: {searchParams.get("type")}</p><br/>
        </div>
    );
}
;

export default VkExchangeToken;

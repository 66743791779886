import React, {useEffect, useState} from 'react';
import styles from './RegistrationForm.module.css';
import axios from "axios";
import config from "../../../settings/config";
import {checkToken} from "../../Utils/TokenService";
import {useNavigate} from "react-router-dom";

const RegistrationForm = () => {

    const carData = [
        {
            brand: 'Tesla',
            models: ['Model S', 'Model 3', 'Model X', 'Model Y']
        },
        {
            brand: 'Nissan',
            models: ['Leaf', 'Ariya']
        },
        {
            brand: 'BMW',
            models: ['i3', 'iX', 'i4']
        }
        // Добавьте другие марки и модели здесь
    ];

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        birthday: '',
        carBrand: '',
        carModel: ''
    });
    const navigate = useNavigate();
    // Получаем токен из localStorage
    const token = localStorage.getItem('m_token');
    // и проверяем его
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [error, setError] = useState(''); //Ошибка для поля емейла

    const handleChange = (e) => {
        const {name, value} = e.target;
        //setFormData({...formData, [name]: value});
        // Если поле - это email, запускаем валидацию
        if (name === 'email') {
            validateEmail(value);
        }
        // Если поле - это модель, ищем марку по этой модели
        if (name === 'carModel') {
            // Найдите бренд, связанный с выбранной моделью
            const selectedBrand = carData.find(car =>
                car.models.includes(value)
            )?.brand || '';

            setFormData({
                ...formData,
                carBrand: selectedBrand, // Устанавливаем бренд
                carModel: value           // Устанавливаем модель
            });
        } else {
            // Обработка других полей формы
            setFormData({...formData, [name]: value});
        }
    };

    const validateEmail = (email) => {
        // Регулярное выражение для проверки email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Пожалуйста, введите корректный e-mail.');
        } else {
            setError('');
        }
    };

    useEffect(() => {
        // Проверка, заполнены ли поля name и email
        if (formData.name.trim() !== '' && formData.email.trim() !== '') {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [formData]);

    // Конфигурация заголовков с Bearer токеном
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    };
    // Отправляем заполненный профайл на сервер
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${config.app.host}/api/v1/user/profile`, formData, axiosConfig)
            .then(response => {
                console.log('Profile updated:', response.data);
                navigate('/postreg', {state: {name: formData.name}});
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`;
    }; // Функция форматирования даты

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Создание аккаунта</h1>
                <p>Кажется, вы здесь впервые. Введите свои данные, чтобы получить персонализированный доступ к зарядным
                    станциям.</p>
                <p className={styles.requiredNote}>*Поля обязательные для заполнения</p>
            </div>
            <div className={styles.formContainer}>
                <form className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Как вас зовут?*</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Введите имя"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Ваш e-mail*</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Введите свою электронную почту"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {error && <p className={styles.error}>{error}</p>}
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="birthdaybirthday">Ваш день рождения</label>
                        <input
                            type="date"
                            id="birthday"
                            name="birthday"
                            value={formData.birthday}
                            onChange={handleChange}
                        />
                    </div>
                    {/* <div className={styles.formGroup}>
                        <label htmlFor="car">Марка и модель электромобиля</label>
                        <select
                            id="car"
                            value={formData.carBrand}
                            name="carBrand"
                            onChange={handleChange}
                        >
                            <option>Не выбрано</option>
                             Список моделей можно будет добавить
                        </select>
                    </div>*/}
                    <div className={styles.formGroup}>
                        <label htmlFor="carModel">Марка и модель электромобиля</label>
                        <select
                            id="carModel"
                            name="carModel"
                            value={formData.carModel}
                            onChange={handleChange}
                        >
                            <option value="">Не выбрано</option>
                            {carData.map((car) => (
                                <optgroup key={car.brand} label={car.brand}>
                                    {car.models.map((model) => (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                    </div>
                </form>
                <div className={styles.submitWrapper}>
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={isButtonEnabled ? styles.submitButtonEnabled : styles.submitButtonDisabled}

                    >
                        Далее
                        <span className={styles.arrow}>→</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;

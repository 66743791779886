import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeScreen.module.css'
import {PinInput} from 'react-input-pin-code';
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import {DeviceUUID} from "device-uuid";
import config from "../../../settings/config";
import {checkToken} from "../../Utils/TokenService";

const PinCodeScreen = () => {

    const [values, setValues] = React.useState(['', '', '', '']);
    const navigate = useNavigate();

    function handlePinInput(value, index, values) {
        setValues(values);
    }

    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);


    function handlePinComplite(values) {
        const storagePin = localStorage.getItem('pin');
        if (values.join('') === storagePin) {
            async function tokenUpdateHandle() {
                let uuid = new DeviceUUID().get();
                const authRequest = {
                    username: localStorage.getItem('phone').replace(/\D/g, ''),
                    password: null,
                    deviceId: uuid
                }
                try {
                    const response = await fetch(`${config.app.host}/api/v1/protected/updtoken`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(authRequest),
                    });

                    if (!response.ok) {
                        toast.error('Error ', {
                            position: "top-center",
                            autoClose: 3000
                        });
                    }

                    const token = await response.json();
                    localStorage.setItem('s_token', token.skunsToken);
                    /*toast.success('C - tокен успешно обновлён', {
                        position: "top-center",
                        autoClose: 3000
                    });*/

                } catch (error) {
                    toast.error('Ошибка:' + error.message, {
                        position: "top-center-center",
                        autoClose: 3000
                    });
                }

            }

            tokenUpdateHandle()
            navigate('/dash');
        } else {
            toast.error('Неверный PIN-код', {
                position: "top-center",
                autoClose: 3000
            });
            values.fill('');
            setValues(values);
            navigate('/');
            return;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.pinContainer}>
                <div className={styles.pinText}> Введите PIN-код:</div>
                <PinInput
                    values={values}
                    onChange={handlePinInput}
                    onComplete={handlePinComplite}
                    autoFocus
                />
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.button}
                        onClick={() => {
                            localStorage.removeItem('pin');
                            navigate('/');
                        }}
                >Сбросить pin-код
                </button>
            </div>
        </div>
    );
}

export default PinCodeScreen;
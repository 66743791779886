import config from "../../../settings/config";

export const updateSkunsToken = async (m_token) => {
    try {
        const response = await fetch(`${config.app.host}/api/v1/protected/updtoken`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            //  throw new Error('Network response was not ok');
            console.log(response.status);
        }

        return await response.json();

    } catch (error) {
        console.log(error.message);
    }
}

import React, {useEffect, useState} from 'react';
import styles from './RegistrationStep7.module.css';
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap'; // Импортируем Modal из react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Добавляем стили Bootstrap

// ВНИМАНИЕ КОМПОНЕНТ НЕ ИСПОЛЬЗУЕТСЯ!!!
const RegistrationStep7 = (props) => {
    const config = require('../../../settings/config.js');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const token = localStorage.getItem('s_token');
    const groupId = JSON.parse(localStorage.getItem('groupId'));

    const [isOpen, setIsOpen] = useState(false); // для кнопки "технические подробности"
    const [statResult, setStatResult] = useState([]);
    const [show, setShow] = useState(false); // для модального окна

    const toggle = () => setIsOpen(!isOpen);
    // Функции для управления модальным окном
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {state} = useLocation();
    const {item} = state;

    useEffect(() => {
        let intervalId;
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/connectors?sn=${item.sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    toast.error('Response not ok ', {
                        position: 'top-center',
                        autoClose: 3000,
                    });
                }

                const result = await response.json();
                setData(result);
            } catch (error) {
                toast.error('Response not ok ' + error, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            }
        };

        fetchData();

        intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const startCharge = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/start?sn=${item.sn}&connectorId=${data[0].connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result.content);
        } catch (error) {
            console.error(error);
        }
    };

    const stopCharge = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/stop?sn=${item.sn}&connectorId=1&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result.content);
        } catch (error) {
            console.error(error);
        }
    };

    async function getStatistics() {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/local-stat-tab/all-transactions?sn=${item.sn}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setStatResult(result);
            handleShow(); // модальное окно
            console.log(statResult);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center align-items-center"
             style={{height: '100vh'}}>
            {/* Модальное окно */}
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Статистика транзакций</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Проверяем, есть ли данные */}
                    {statResult.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                {/*<th>ID</th>*/}
                                {/*<th>Тип</th>*/}
                                {/*<th>ID Тэг</th>*/}
                                <th>Энергия (кВтч)</th>
                                <th>Начало</th>
                                <th>Конец</th>
                                <th>Ошибка</th>
                                {/*<th>Причина остановки</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {/* Перебираем данные и отображаем в таблице */}
                            {statResult.map((stat) => (
                                <tr key={stat.id}>
                                    {/*<td>{stat.id}</td>*/}
                                    {/* <td>{stat.type}</td>*/}
                                    {/*<td>{stat.idTag}</td>*/}
                                    <td>{stat.consumeEnergy}</td>
                                    <td>{new Date(stat.dataStartTransaction).toLocaleString()}</td>
                                    <td>{new Date(stat.dataEndTransaction).toLocaleString()}</td>
                                    <td>{stat.stopError}</td>
                                    {/*<td>{stat.reasonStop || 'Не указана'}</td>*/}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Нет данных для отображения</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.roundButtons}></div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "Page title"*/}

                {/*start row block -- "An SMS will be sent to your number" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Управление станцией: {item.nickName} </h5>
                                    <div className="text-center">
                                        {data ? (
                                            <h3>
                                                <span
                                                    className="badge bg-secondary rounded-pill"> Статус коннектора: {data[0]?.status}</span>
                                            </h3>
                                        ) : (
                                            <h3>No data</h3>
                                        )}
                                        <div className={styles.buttonsContainerCenter}>
                                            <div>
                                                <button onClick={startCharge} className={`btn btn-primary`}>
                                                    Старт зарядки
                                                </button>
                                            </div>
                                            &nbsp;&nbsp;
                                            <div>
                                                <button onClick={stopCharge} className={`btn btn-danger`}>
                                                    Стоп зарядки
                                                </button>
                                            </div>
                                        </div>

                                        <div className={styles.buttonsContainerCenter}>
                                            <button onClick={getStatistics} className={`btn btn-info`}>
                                                История зарядок
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block - "An SMS will be sent to your number"*/}

                {/*start row block - "Phone number and change it"*/}
                <div className={styles.row2}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        {data ? (
                            <>
                                <h4 onClick={toggle} style={{cursor: 'pointer', color: 'blue'}}
                                    className="btn btn-light btn-sm">
                                    Технические подробности
                                </h4>
                                <div className={`collapse ${isOpen ? 'show' : ''}`}>
                                    {item.nickName} Статус коннектора: {data[0]?.status}
                                    <pre> {JSON.stringify(data[0], null, 2)}</pre>
                                </div>
                            </>
                        ) : (
                            <h3>No data</h3>
                        )}
                    </div>
                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row4}>{/*r4c1*/}</div>
                <div className={styles.column2}></div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row5}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    {/*new line*/}
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationStep7;

import React, {useEffect, useState} from 'react';
import {Card, ListGroup} from 'react-bootstrap';
import config from "../../../settings/config";
import {toast} from "react-toastify";
import {checkToken} from "../../Utils/TokenService";
import {useNavigate} from "react-router-dom";

const ConnectorStatus = (props) => {
    const {
        slaveId,
        type,
        transactionId,
        evRequestedVoltage,
        evRequestedCurrent,
        evRequestedPower,
        fullStationConsumedEnergy,
        status,
        soc,
        consumeEnergy,
        power,
        chargeTime,
        amperage,
        connectorError,
        chargePointVendorError,
        temperaturePwM,
        versionController,
        sn,
    } = props;

    const navigate = useNavigate();

    const token = localStorage.getItem('s_token');

    const [connectorsStatus, setConnectorsStatus] = useState(null);
    const [connector, setConnector] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг перед каждым запросом
            if (!checkToken(token)) {
                navigate('/step2');
            }
            try {
                const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/connectors?sn=${sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    toast.error('Response not ok', {
                        position: 'top-center',
                        autoClose: 3000,
                    });
                    return;
                }

                const result = await response.json();

                // Найти объект с connectorId равным 1
                const foundConnector = result.find(item => item.connectorId === slaveId);
                setConnector(foundConnector);
            } catch (error) {
                toast.error('Response error: ' + error, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            }
        };

        fetchData();

        // Запуск интервала для опроса каждые 15 секунд
        const intervalId = setInterval(fetchData, 15000);
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);

    }, [sn, token]); // Добавление зависимостей

    return (
        <Card className="mb-4 shadow-sm">
            <Card.Header as="h5" className="bg-primary text-white">Connector Status: {connector?.status}</Card.Header>


            {/*<ListGroup.Item><strong>Slave ID:</strong> {slaveId}</ListGroup.Item>
                    <ListGroup.Item><strong>Type:</strong> {type}</ListGroup.Item>
                    <ListGroup.Item><strong>Transaction ID:</strong> {transactionId}</ListGroup.Item>
                    <ListGroup.Item><strong>Status:</strong> {status}</ListGroup.Item>
                    <ListGroup.Item><strong>EV Requested Voltage:</strong> {evRequestedVoltage} V</ListGroup.Item>
                    <ListGroup.Item><strong>EV Requested Current:</strong> {evRequestedCurrent} A</ListGroup.Item>
                    <ListGroup.Item><strong>EV Requested Power:</strong> {evRequestedPower} kW</ListGroup.Item>
                    <ListGroup.Item><strong>Full Station Consumed Energy:</strong> {fullStationConsumedEnergy} kWh</ListGroup.Item>
                    <ListGroup.Item><strong>SOC:</strong> {soc} %</ListGroup.Item>*/}
            <Card.Body>
                {connector?.status === "Charging" ? (
                    <ListGroup variant="flush">
                        <ListGroup.Item><strong>Consumed Energy:</strong> {consumeEnergy} kWh</ListGroup.Item>
                        <ListGroup.Item><strong>Power:</strong> {power} kW</ListGroup.Item>
                        <ListGroup.Item><strong>Charge Time:</strong> {chargeTime} seconds</ListGroup.Item>
                        <ListGroup.Item><strong>Amperage:</strong> {amperage} A</ListGroup.Item>
                        <ListGroup.Item><strong>Connector Error:</strong> {connectorError}</ListGroup.Item>
                    </ListGroup>
                ) : (
                    <p className="text-center">Нет активной сессии</p>
                )}
            </Card.Body>
                    {/*<ListGroup.Item><strong>Charge Point Vendor Error:</strong> {chargePointVendorError}</ListGroup.Item>
                    <ListGroup.Item><strong>Temperature PWM:</strong> {temperaturePwM} °C</ListGroup.Item>
                    <ListGroup.Item><strong>Controller Version:</strong> {versionController}</ListGroup.Item>*/}


        </Card>
    );
};

export default ConnectorStatus;

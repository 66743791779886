import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './HelpIcon.module.css';

const HelpIcon = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Сопоставляем пути страниц с соответствующими путями помощи
    const helpRoutes = {
        '/step2': '/help/helpStep2',
        '/registration': '/help/registration',
        '/profile': '/help/profile',
        // Добавьте другие страницы по мере необходимости
    };

    const handleClick = () => {
        const currentPath = location.pathname;
        // Определяем путь к странице помощи, если он существует в helpRoutes
        const helpPath = helpRoutes[currentPath] || '/help/default';
        navigate(helpPath);
    };

    return (
        <button onClick={handleClick} className={styles.helpIcon}>
            ?
        </button>
    );
};

export default HelpIcon;

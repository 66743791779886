import config from "../../../settings/config";
import {DeviceUUID} from "device-uuid";

export const getOtpCode = async (phone) => {
    let uuid = new DeviceUUID().get();
    try {
        const response = await fetch(`${config.app.host}/api/v1/getotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: phone.replace(/\D/g, ''), deviceId: uuid,}),
        });

        if (!response.ok) {
            throw new Error(response.status + ': ' + response.statusText);
        }

        return await response.json();

    } catch (error) {
        throw new Error(error.message);
    }
}
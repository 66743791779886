//import './App.css';
import {AuthProvider} from "./context/AuthContext";
//import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {HashRouter, Route, Routes} from 'react-router-dom';
import HomeScreen from "./component/HomeScreen/HomeScreen";
import RegisterNewUserForm from "./component/Registration/RegisterNewUserForm";
import RegistrationStep2 from "./component/MainRoad/Step2/RegistrationStep2";
import RegistrationStep3 from "./component/MainRoad/Step3/RegistrationStep3";
import {ToastContainer} from "react-toastify";
import RegistrationStep4 from "./component/MainRoad/Step4/RegistrationStep4";
import PinCodeScreen from "./component/PinCodeScreen/InputPinCode/PinCodeScreen";
import PinCodeOffer from "./component/PinCodeScreen/OfferPin/Step1/PinCodeOffer";
import PinCodeOffer2 from "./component/PinCodeScreen/OfferPin/Step2/PinCodeOffer2";
import StartScreen from "./component/StartScreen/StartScreen";
import RegistrationStep5 from "./component/MainRoad/Step5/RegistrationStep5";
import Generic from "./component/MainRoad/Generic/Generic";
import RegistrationStep6 from "./component/MainRoad/Step6/RegistrationStep6";
import RegistrationStep7 from "./component/MainRoad/Step7/RegistrationStep7";
import VkExchangeToken from "./component/MainRoad/VkAuth/VkExchangeToken";
import UserProfile from "./component/UserProfile/UserProfile";
import RegistrationStep55 from "./component/MainRoad/Step55/RegistrationStep55";
import ManageConnector from "./component/MainRoad/Step55/ManageConnector/ManageConnector";
import {useState} from "react";
import ChargingStationList from "./component/TestComponent/ChargingStationList/ChargingStationList";
import NStartScreen from "./component/MainRoad/StartScreen/NStartScreen";
import CheckProfile from "./component/UserProfile/CheckProfile";
import RegistrationForm from "./component/MainRoad/RegistrationForm/RegistrationForm";
import PostRegScreen from "./component/MainRoad/PostRegScreen/PostRegScreen";
import PostRegScreen2 from "./component/MainRoad/PostRegScreen2/PostRegScreen2";
import Dashboard from "./component/MainRoad/Dashboard/Dashboard";
import StationCard from "./component/MainRoad/StationCard/StationCard";
import HelpStep2 from "./component/MainRoad/Help/HelpStep2";
import DefaultHelp from "./component/MainRoad/Help/DefaultHelp";
import Header from "./component/Header/Header";

function App() {
    const [title, setTitle] = useState("PSS Charge"); // Состояние для заголовка
    return (
        <AuthProvider>

            <HashRouter>
                <Header title={title}/>
                <Routes>
                    <Route path="/" element={<StartScreen setTitle={setTitle}/>}/>
                    <Route path="/step2" element={<RegistrationStep2 setTitle={setTitle}/>}/>
                    <Route path="/home" element={<HomeScreen setTitle={setTitle}/>}/>
                    <Route path="/register" element={<RegisterNewUserForm setTitle={setTitle}/>}/>
                    <Route path="/step3" element={<RegistrationStep3 setTitle={setTitle}/>}/>
                    <Route path="/step4" element={<RegistrationStep4 setTitle={setTitle}/>}/>
                    <Route path="/step5" element={<RegistrationStep5 setTitle={setTitle}/>}/>
                    <Route path="/step6" element={<RegistrationStep6 setTitle={setTitle}/>}/>
                    <Route path="/step7" element={<RegistrationStep7 setTitle={setTitle}/>}/>
                    <Route path="/pin" element={<PinCodeScreen setTitle={setTitle}/>}/>
                    <Route path="/pinoffer" element={<PinCodeOffer setTitle={setTitle}/>}/>
                    <Route path="/pinoffer2" element={<PinCodeOffer2 setTitle={setTitle}/>}/>
                    <Route path="/generic" element={<Generic setTitle={setTitle}/>}/>
                    <Route path="/vkexchange" element={<VkExchangeToken setTitle={setTitle}/>}/>
                    <Route path="/profile" element={<UserProfile setTitle={setTitle}/>}/>
                    <Route path="/step55" element={<RegistrationStep55 setTitle={setTitle}/>}/>
                    <Route path="/manage" element={<ManageConnector setTitle={setTitle}/>}/>
                    <Route path="/csl" element={<ChargingStationList setTitle={setTitle}/>}/>
                    <Route path="/start" element={<NStartScreen setTitle={setTitle}/>}/>
                    <Route path="/checkProfile" element={<CheckProfile/>}/>
                    <Route path="/regform" element={<RegistrationForm/>}/>
                    <Route path="/postreg" element={<PostRegScreen/>}/>
                    <Route path="/postreg2" element={<PostRegScreen2/>}/>
                    <Route path="/dash" element={<Dashboard/>}/>
                    <Route path="/stcard" element={<StationCard/>}/>
                    <Route path="/help/default" element={<DefaultHelp/>}/>
                    <Route path="/help/helpStep2" element={<HelpStep2/>}/>
                </Routes>
            </HashRouter>
            <ToastContainer
                hideProgressBar
            />
        </AuthProvider>
    );
}

export default App;

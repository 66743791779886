import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {fetchConnectors} from '../FetchServises/ConnectorService';
import {checkToken} from "../../Utils/TokenService"; // Импортируем функцию
import styles from './RegistrationStep55.module.css';
import ConnectorCard from "./ConnectorInfo/ConnectorCard";
import HelpIcon from "../HelpIcon/HelpIcon";
import {updateSkunsToken} from "../FetchServises/UpdSkunsTokenService";
import {toast} from "react-toastify";

const RegistrationStep55 = (props) => {
    const [connectors, setConnectors] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('s_token'));
    const location = useLocation();
    const {chargeStation} = location.state || {};

    const navigate = useNavigate();

    // Чекаем токен мобильного приложения
    useEffect(() => {
        const m_token = localStorage.getItem('m_token');
        if (!checkToken(m_token)) {
            toast.error('Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });
            navigate('/step2');
        }
    }, []);

    useEffect(() => {
        props.setTitle('Коннекторы');
    }, [props, props.setTitle]);

    useEffect(() => {
        // Фетчим коннекторы
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            if (!checkToken(token)) {
                try {
                    const renewToken = async () => {
                        const newToken = await updateSkunsToken(localStorage.getItem('m_token'));
                        localStorage.setItem('s_token', newToken.skunsToken);
                        setToken(newToken.skunsToken);
                        console.log("renewToken");
                    }
                    renewToken();
                } catch (error) {
                    console.log(error.message);
                }
            }

            const result = await fetchConnectors(chargeStation.sn, token); // Используем импортированную функцию
            if (result) {
                setConnectors(result);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 15000);
        return () => clearInterval(intervalId);

    }, [chargeStation.sn, token]);

    useEffect(() => {
        console.log(chargeStation);
        console.log(connectors);
    }, [chargeStation, connectors]);

    return (
        <div className={`container ${styles.containerWithBackground}`}>
            {connectors.length > 0 ? (
                connectors.map((connector) => {
                    if (connector.type === 'station') {
                        return null;
                    }
                    return (<ConnectorCard {...connector} />)
                })
            ) : (<p>Loading connectors...</p>)}
            <HelpIcon/>
        </div>

        /*<div className={`container mt-4 ${styles.containerWithBackground}`}>
            <div>
                {connectors.length > 0 ? (
                    connectors.map(connector => {
                        if (connector.type === 'station') {
                            return null;
                        }
                        return (
                            <div className="col-md-6 mb-4" key={connector.id}>
                                <ConnectorCard {...connector} />
                            </div>
                        )
                    })
                ) : (
                    <p>Loading connectors...</p>
                )}
            </div>
        </div>*/
    );
};

export default RegistrationStep55;

import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer2.module.css'
import {PinInput} from 'react-input-pin-code';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import HelpIcon from "../../../MainRoad/HelpIcon/HelpIcon";

const PinCodeOffer2 = () => {

    const [values, setValues] = useState(['', '', '', '']);
    const {state} = useLocation();
    const navigate = useNavigate();
    const [complite, setComplite] = useState(false)

    function handlePinInput(value, index, values) {
        setValues(values);
    }

    function handlePinComplite(values) {
        if (values.join('') !== state.firstValues.join('')) {
            navigate('/pinoffer', {state: {error: true}});
        } else {
            setComplite(true);
            localStorage.setItem('pin', values.join(''));
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.pinContainer}>
                <div style={complite ? {display: 'none'} : null}>
                    <div className={`${styles.pinText} text-center`}>Повторите PIN-код:</div>
                    <div className="d-flex justify-content-center">
                        <PinInput
                            values={values}
                            //onChange={(value, index, values) => setValues(values)}
                            onChange={handlePinInput}
                            onComplete={handlePinComplite}
                            autoFocus
                            placeholder="😀"
                            size="lg"
                        />
                    </div>
                </div>
                <div style={!complite ? {display: 'none'} : null} className="text-center">
                    <div className={styles.complite}>Пин - код сохранён в приложении<br/>
                        {/*<NavLink to="/step5" className="btn btn-primary">Продолжить</NavLink></div>*/}
                        <NavLink to="/checkProfile" className="btn btn-primary">Продолжить</NavLink></div>
                </div>
            </div>
            <HelpIcon/>
        </div>
    );
}

export default PinCodeOffer2;